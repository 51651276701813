#home-page{
	// == Banners ==
	.banners-section{
		position: relative;
		width: 100%;

		.swiper-container{
			.swiper-slide{
				img{
					width: 100%;
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}

			.swiper-button-prev{
				left: 16px;
			}
			.swiper-button-next{
				right: 16px;
			}
		}
	}
	// == ==

	// == Comprar & Vender ==
	.purchase-sale-section{
		position: relative;
		width: 100%;
		padding-top: 44px;
		padding-bottom: 52px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('../images/pages/home/sale-bg.png');

		.col-titles{
			margin-bottom: 26px;
			text-align: center;

			.title{
				color: $purple;
				font-size: 2.4rem;
				font-weight: 600;
			}

			.subtitle{
				margin-top: 2px;
				color: #8800e9;
				font-size: 1.19rem;
				font-weight: 600;
			}
		}

		.col-opt{
			text-align: center;

			.circle{
				display: inline-block;
				margin-bottom: 5px;

				img{
					width: 300px;
					max-width: 95%;
					border-radius: 50%;
					box-shadow: 0px 2px 5px rgba(0,0,0,0.50);
				}
			}

			.num{
				color: $purple2;
				font-size: 2.8rem;
				font-weight: 600;
			}

			p{
				color: #545454;
				font-size: 1.35rem;
				font-weight: 600;
			}
		}

		@media screen and (min-width: 992px){
			.col-opt{
				&:first-child, &:last-child{
					margin-top: 35px;
				}
			}
		}
		@media screen and (max-width: 991px){
			.col-opt{
				margin-bottom: 18px;
			}
		}
		@media screen and (max-width: 767px){
			.col-opt{
				margin-bottom: 22px;

				.circle{
					img{
						max-width: 100%;
					}
				}
			}
		}
		@media screen and (max-width: 575px){
			.col-titles{
				.title{
					font-size: 1.95rem;
				}

				.subtitle{
					font-size: 1.08rem;
				}
			}
		}

		&.purchase-bg{
			background-image: url('../images/pages/home/purchase-bg.png');

			.col-titles{
				.title, .subtitle{
					color: #ffffff !important;
				}
			}

			.col-opt{
				.num, p{
					color: #ffffff !important;
				}
			}
		}

	}
	// == ==

	// == Newsletter ==
	.newsletter-section{
		position: relative;
		padding: 24px 15px 33px 15px;
		width: 100%;
		background-color: $purple;

		h2{
			color: #ffffff;
			font-size: 1.9rem;
			text-align: center;
		}

		form{
			margin-top: 18px;
			text-align: center;
			@extend .flex-center-xy;
			flex-wrap: wrap;

			.form-group{
				width: 600px;
				margin-bottom: 0;
				@include placeholder(#ffffff !important, 400, normal, 1.1rem);

				>div{
					width: 100%;
				}

				.form-control{
					height: 46px;
          color: #ffffff;
					text-align: center;
					font-size: 1.1rem;
					border-radius: 0;
					border-color: #ffffff;
					background-color: transparent;
				}
			}

			.btn-send{
				position: relative;
				margin-left: -15px;
				width: 155px;
				height: 46px;
				color: $purple;
				font-size: 1.10rem;
				font-weight: 600;
				text-align: center;
				background-color: #ffffff;
				border-color: #ffffff;
				border-radius: 0;

				&:hover{
					border-color: #f2f9e8;
					background-color: #f2f9e8;
				}
			}
		}

		@media screen and (max-width: 991px){
			form{
				.form-group{
					width: calc(90vw - 155px);
				}
			}
		}
		@media screen and (max-width: 575px){
			h2{
				font-size: 1.4rem;
				line-height: 1.1;
			}

			form{
				.form-group{
					width: calc(100% - 127px);
					@include placeholder(#919293, 400, normal, 0.95rem);

					.form-control{
						height: 38px;
						font-size: 0.95rem;
					}
				}

				.btn-send{
					width: 127px;
					height: 38px;
					font-size: 1.0rem;
				}
			}
		}
	}
	// == ==
}

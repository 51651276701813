.tr-150{
  @include transition(150ms);
}

.tr-250{
  @include transition(250ms);
}

.tr-400{
  @include transition(400ms);
}

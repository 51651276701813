#header{

	.navbar{
		background-color: #ffffff !important;
		// border-bottom: 1px solid $purple;

		.navbar-brand{
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;

			img{
				height: 80px;
			}
		}

		.navbar-toggler{
			border: 0;

			.bar-icon{
				color: $purple;
				font-size: 1.7rem;

				&:hover{
					color: $purple2;
				}
			}

			.navbar-toggler-icon{
				display: none;
			}
		}

		#nav-collapse{
			.navbar-nav{
				.nav-item{
					.nav-link{
						position: relative;
						color: $purple;
						font-size: 1.12rem;

						&:after{
							content: " ";
							position: absolute;
							bottom: -4px;
							left: 0;
							width: 100%;
							border-top: 1px solid $purple;
							opacity: 0;
							@include transition(250ms);
						}

						&:hover{
							color: $purple2;

							&:after{
								bottom: 1px;
								opacity: 1;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px){
			#nav-collapse{
				.navbar-nav{
					.nav-item{
						margin-left: 7px;
						margin-right: 7px;
					}
				}
			}

			@media screen and (max-width: 1199px){
				.navbar-brand{
					img{
						height: 66px;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.nav-item{
							.nav-link{
								font-size: 1.08rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			.navbar-brand{
				padding-top: 0.1rem;
				padding-bottom: 0.1rem;

				img{
					height: 45px;
				}
			}

			#nav-collapse{
				.navbar-nav{
					margin-top: 7px;

					.nav-item{
						.nav-link{
							padding-top: 0.1rem;
							padding-bottom: 0.1rem;
							font-size: 1.05rem;
						}
					}
				}
			}

		}

	}

}
